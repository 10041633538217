window.Client = {
	init: function() {
		var label_map = {
	        'BWI': {
	            'num': 10,
	            'event_idx': 1,
	            'req_idx': [2,3],
	            'req_type': 'input'
	        },
	        'BWV': {
	            'num': 10,
	            'event_idx': 1,
	            'req_idx': [2,3],
	            'req_type': 'select'
	        },
	        'BD':  {
	            'num': 5,
	            'event_idx': 1,
	            'req_idx': [2,3],
	            'req_type': 'input'
	        },
	        'BSI': {
	            'num': 10,
	            'event_idx': 1,
	            'req_idx': [2,3],
	            'req_type': 'select'
	        },
	        'BSV': {
	            'num': 10,
	            'event_idx': 1,
	            'req_idx': [2,3],
	            'req_type': 'select'
	        },
	        'HWI': {
	            'num': 10,
	            'event_idx': 1,
	            'req_idx': [2,3],
	            'req_type': 'input'
	        },
	        'HWV': {
	            'num': 10,
	            'event_idx': 1,
	            'req_idx': [2,3],
	            'req_type': 'select'
	        },
	        'HD':  {
	            'num': 5,
	            'event_idx': 1,
	            'req_idx': [2,3],
	            'req_type': 'input'
	        },
	        'HSI': {
	            'num': 10,
	            'event_idx': 1,
	            'req_idx': [2,3],
	            'req_type': 'select'
	        },
	        'HSV': {
	            'num': 10,
	            'event_idx': 1,
	            'req_idx': [2,3],
	            'req_type': 'select'
	        }
	    };
	    
	    var selects = document.querySelectorAll('select[data-label^=B], select[data-label^=H]');
	    
	    selects.forEach(el => {
	        let prefix = el.dataset.label.replace(/([A-Z]{2,3})\d+\_\d+/,"$1");
	        let block_idx = parseInt(el.dataset.label.replace(/[A-Z]{2,3}(\d+)\_\d+/,"$1"));
	        let field_idx = parseInt(el.dataset.label.replace(/[A-Z]{2,3}\d+\_(\d+)/,"$1"));
	        
	        el.addEventListener('change', (function(bid,fid,prefix) {
	            //console.log(bid,fid,prefix);
	            let map = label_map[prefix];
	            let selectors = map.req_idx.map(idx => { return map.req_type + '[data-label="' + prefix + bid + '_' + idx + '"]' }).join();
	            
	            if (this.selectedIndex == 0) {
	                document.querySelectorAll(selectors).forEach(el => {
	                    el.removeAttribute('required');
	                });
	            } else {
	                document.querySelectorAll(selectors).forEach(el => {
	                    el.setAttribute('required', 'required');
	                });
	            }
	            
	            
	            
	            // allow default select again
	            this.children[0].removeAttribute('disabled');
	            
	            
	        }).bind(el, block_idx, field_idx, prefix));
	    });
	    
	},

	onRender: function(screenId) {
	    
	}
}